<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="fs-3 fw-bold mb-3">{{ label }}</div>
      </div>
      <div class="col-auto" v-if="inputable">
        <button class="btn btn-sm btn-success" @click="addInput">+</button>
      </div>
    </div>

    <div v-for="(v, k) in inputArrayData" :key="k">
      <div class="row mt-3">
        <div class="col">
          <input
            :name="fieldname"
            :disabled="disabled"
            type="text"
            class="form-control"
            v-model="inputArrayData[k]"
          />
        </div>
        <div class="col-auto" v-if="inputable">
          <button class="btn btn-danger btn-sm mt-1" @click="removeInput(k)">
            X
          </button>
        </div>
      </div>
    </div>
    <ErrorMessage :name="fieldname" class="text-danger error-feedback" />
  </div>
</template>
<script>
import { toRaw } from "vue";
import {
  // Field,
  ErrorMessage,
} from "vee-validate";

export default {
  components: {
    // Field,
    ErrorMessage,
  },
  props: {
    fieldname: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    preData: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inputable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      inputArrayData: [],
    };
  },
  watch: {
    inputArrayData: {
      handler(val) {
        this.$emit("changeValue", val);
      },
      deep: true,
    },
  },
  mounted() {
    this.inputArrayData = this.preData;
  },
  methods: {
    addInput() {
      this.$emit("addInput", true);
      // this.inputArrayData.push("");
    },
    removeInput(index) {
      this.$emit("removeInput", index);
      // this.inputArrayData.push("");
    },
    getArrayInput() {
      return toRaw(this.inputArrayData);
    },
  },
};
</script>
