<template>
  <div>
    <div v-if="!openCam">
      <div v-if="url">
        <iframe class="preview" :src="url" frameborder="0"></iframe>
      </div>
      <img
        v-else
        class="img-fluid mb-3 image-profile"
        :src="imageUrl ? imageUrl : '/assets/media/avatars/blank.png'"
        alt=""
      />
      <div>
        <label>{{ label }}</label>
      </div>
      <span
        v-if="!isChange && !disabled"
        class="btn btn-success"
        @click="isChange = !isChange"
      >
        Perbaharui Foto
      </span>
      <div class="row" v-if="isChange">
        <div class="col">
          <input
            type="file"
            class="form-control"
            :accept="accept"
            @change="onFileChange"
            :disabled="disabled"
          />
        </div>
        <div class="col-auto text-right" v-if="!disabled">
          <span class="btn btn-success" @click="handleUpload">Upload</span>
          <span class="btn btn-secondary" @click="isChange = !isChange">
            Batal
          </span>
        </div>
      </div>
    </div>
    <div v-if="needverify && isChange">
      <Camera :open-cam="openCam" @use-photo="handleUseImage"></Camera>
      <div class="btn btn-sm btn-success mt-5" @click="openCam = !openCam">
        {{ !openCam ? "Verifikasi" : "Batalkan" }}
      </div>
    </div>
  </div>
</template>
<style>
.preview {
  width: 100%;
  min-height: 300px;
  max-height: 500px;
}
.image-profile {
  width: auto;
  min-height: 100px;
  max-height: 250px;
}
</style>

<script>
import Service from "@/services/base.service";
import Camera from "../Camera.vue";
import { toast } from "vue3-toastify";
import { showLoading, hideLoading } from "loading-request";
import "loading-request/dist/index.css";

export default {
  components: {
    Camera,
  },
  props: {
    accept: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    uuid: {
      type: String,
      default: "",
    },
    needverify: { type: Boolean, default: false },
  },
  data() {
    return {
      url: null,
      file: null,
      imageUrl: null,
      fileUploaded: false,
      openCam: false,
      isChange: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.currentUrl();
    }, 1000);
  },
  watch: {},
  methods: {
    currentUrl() {
      if (this.uuid) {
        const attachmentService = new Service("attachment/download");
        attachmentService.downloadFile(this.uuid).then((blob) => {
          const urlCreator = window.URL || window.webkitURL;
          this.imageUrl = urlCreator.createObjectURL(blob);
        });
      }
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.file = e.target.files ? e.target.files[0] : null;
      this.url = URL.createObjectURL(file);
    },
    async handleUpload() {
      this.fileUploaded = false;
      if (!this.file) {
        this.$swal.fire({
          icon: "error",
          title: "Silahkan Pilih file terlebih dahulu",
        });
        return;
      }
      try {
        showLoading({
          message: "Uploading file...",
        });
        const formData = new FormData();
        formData.append("file", this.file);
        const BaseService = new Service("attachment/upload");
        const { data } = await BaseService.createData(formData);
        this.$emit("fileUploaded", data);
        this.$swal.fire({
          icon: "success",
          title: "File telah terupload",
        });
        this.fileUploaded = true;
        if (this.needverify && this.isChange) {
          this.openCam = true;
        }
      } catch (error) {
        console.log(error);
        toast.error(error.message);
      } finally {
        hideLoading();
      }
    },
    isFileSlected() {
      return !this.url;
    },
    isUnUploaded() {
      return this.url && this.fileUploaded;
    },
    async handleUseImage(value) {
      try {
        const base64Response = await fetch(`${value}`).then((r) => r.blob());
        const file = new File([base64Response], "selfie-verification.jpg");
        const formData = new FormData();
        formData.append("file", file);
        const BaseService = new Service("attachment/upload");
        const { data } = await BaseService.createData(formData);
        this.$emit("fileVerificationUploaded", data);
        this.$swal.fire({
          icon: "success",
          title: "File telah terupload",
        });
        this.openCam = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
